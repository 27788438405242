// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import getRandomArbitraryInt from '@bodhi-project/components/lib/methods/getRandomArbitraryInt'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import Link from '../components/link'
import '../components/link/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import seoHelper from '../methods/seoHelper'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageData = {
  pageTitle: 'Digital Sovereignty',
  nakedPageSlug: 'digital-sovereignty',
  pageAbstract: 'Sorry, the page you were looking for does not exist!',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    cover2: file(relativePath: { eq: "cover2.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = (props) => (
  <StandardPageWrapper
    className=""
    seoData={seoData}
    {...props}
    style={{ background: '#2A2A2A' }}
  >
    <h1 style={{ color: '#F9F2E2', marginBottom: '1.24rem' }}>
      <big>Towards a world of positive technology</big>
    </h1>
    <p style={{ color: '#F9F2E2', marginBottom: '1.24rem' }}>
      <big>
        <big>
          At this moment in time we live in a world where data is the new
          commercial commodity, and you are being made into a product! We, as a
          people, face the choice either to govern ourselves, or to let
          ourselves be governed by private monopolies.
        </big>
      </big>
    </p>
    <p style={{ color: '#F9F2E2' }}>
      <big>
        The data, hardware and software that we rely on, and which has become
        central to our lives and communities, is in the hands of a very small
        number of large technology companies that have private profit as their
        guiding interest. It is important to act now, and we, at Prisma Design,
        are working towards creating resilient platforms that are beyond the
        power of private interest. We hope not only to create these capacities
        for ourselves, but to also offer these competencies, in service, to
        Auroville.
      </big>
    </p>
    <p style={{ color: '#F9F2E2' }}>
      <big>
        We're developing and adopting frameworks such as the{' '}
        <Link to="https://books.prisma.haus/web-books">Web Book</Link> that are
        based on open-standards and open-source technologies. We're also
        participating in revolutionary and futuristic projects that may well be
        the bed-rock for the next generation of technologies like the{' '}
        <Link to="https://dfinity.org/">DFINITY Project</Link> which promises to
        reverse big-tech’s monopolisation of the internet.
      </big>
    </p>
  </StandardPageWrapper>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
